export const environment = {
	production: true,
	servicesBaseUrl: 'https://www.highfieldcore.com',
	signalRBaseUrl: 'https://www.highfieldcore.com/signalr',
	CDNUrl: 'https://secure-cdn.highfieldqualifications.com/',
	dashboardUrl: 'https://dashboard.highfieldcore.com/',
	markingUrl: 'https://marking.highfieldcore.com',
	sentryEnvironment: 'Production',
	sentryReleaseVersion: 'Core@2.3.0',
	sentryTraceRate: 0.2,
	questionBankSystemId: '8FEFF867-2334-4E50-9BF4-182EDE45B9AE',
	coreExamCDNCategoryId: 'f9421d63-dd50-413e-b8b9-ff328d98671b',
	CoreExamsUserId: "db110311-c873-464b-8344-46f7af4c3600",
	LoginPageUrl: "https://authentication.highfieldqualifications.com/login",
	UserApiBaseUrl: "https://authentication.highfieldqualifications.com/",
	AuthApiBaseUrl: "https://authentication.highfieldqualifications.com/auth",
	RegisterPageUrl: "https://authentication.highfieldqualifications.com/register",
	CdnApiUrl: "https://cdn-live.highfieldqualifications.com/"
};
